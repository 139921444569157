/* eslint-disable max-len */
import { ProjectInspectionGallery } from '@sections/gallery';
import { getSEO, PageSEO } from '@seo';
import { graphql } from 'gatsby';
import React from 'react';

interface Props {
  data: any;
  location: {
    pathname: string;
  };
}

const ProjectInspection = ({ data, location }: Props) => {
  const { pathname } = location;
  const seo = getSEO(pathname);

  //* Caption images here
  const iomCaptions = {
    'image-00': {
      body: '',
      title: '',
    },
  };

  type IomImageKey = keyof typeof iomCaptions;

  const iomImages = data.iom.edges.map((obj: any) => {
    const key: IomImageKey = obj.node.name;

    const title =
      iomCaptions[key] && iomCaptions[key].title
        ? iomCaptions[key].title
        : null;
    const body =
      iomCaptions[key] && iomCaptions[key].body ? iomCaptions[key].body : null;

    return {
      body,
      id: obj.node.id,
      imageData: obj.node,
      title,
    };
  });

  return (
    <>
      <PageSEO
        description={seo.description}
        featureImage="og-gallery.jpg"
        pathname={pathname}
        title={seo.title}
      />
      <ProjectInspectionGallery iomImages={iomImages} />
    </>
  );
};

export default ProjectInspection;

export const pageQuery = graphql`
  query {
    iom: allFile(filter: { sourceInstanceName: { eq: "gallery-iom" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
